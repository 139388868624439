<template>
  <div class="b-auth">
    <template v-if="layout === 'login'">
      <login />
    </template>
    <template v-else-if="layout === 'registr'">
      <registr />
    </template>
    <template v-else-if="layout === 'process'">
      <registr-process />
    </template>
    <template v-else-if="layout === 'reset-password'">
      <reset-password />
    </template>
  </div>
</template>

<script>
  import Login from '@/views/auth/Login'
  import Registr from '@/views/auth/Registr'
  import RegistrProcess from '@/views/auth/RegistrProcess'
  import ResetPassword from '@/views/auth/ResetPassword'

  export default {
    components: {
      ResetPassword,
      Login,
      Registr,
      RegistrProcess
    },

    computed: {
      layout() {
        return this.$route.meta.page
      }
    }
  }
</script>
