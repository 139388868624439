<template>
  <div class="b-auth__wrapper">
    <div class="b-auth__form">
      <div class="head-text center">
        <div class="title">
          <img src="../../../public/img/icons/newLogo.svg" />
        </div>
        <div class="desc">Создание аккаунта</div>
      </div>
      <form-info />
      <div class="b-auth__login">
        Уже зарегистрированы?
        <router-link to="/auth/login">Войти</router-link>
      </div>
    </div>
    <slider />
  </div>
</template>

<script>
  import FormInfo from './steps/FormInfo'
  import Slider from './components/Slider.vue'

  export default {
    components: {
      FormInfo,
      Slider
    },

    data: () => ({
      form: {
        name: '',
        login: '',
        password: '',
        confirm_password: '',
        agree: false
      }
    }),

    methods: {
      submit() {}
    }
  }
</script>
