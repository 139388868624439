<template>
  <b-form @submit.prevent="submitHandler">
    <div class="row">
      <div class="col-12 mb-4">
        <label>Как вас зовут?</label>
        <b-form-input
          v-model="form.first_name"
          maxlength="20"
          type="text"
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-4">
        <label>E-mail</label>
        <b-form-input
          v-model="form.login"
          type="text"
          required
          :class="{ 'is-invalid': login_error }"
        />
        <p
          v-if="login_error"
          class="error mt-2"
        >
          Введите корректный email
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-4">
        <label>Контактный телефон</label>
        <b-form-input
          v-model="form.phone"
          v-mask="'+7##########'"
          placeholder="+7XXXXXXXXXX"
          type="text"
          required
          :class="{ 'is-invalid': phone_error }"
        />
        <p
          v-if="phone_error"
          class="error mt-2"
        >
          Введите корректный телефон
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-4">
        <label>Придумайте пароль</label>
        <password-input
          v-model="form.password"
          autocomplete="on"
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-4">
        <label>Повторите пароль</label>
        <password-input
          v-model="form.confirm_password"
          autocomplete="on"
          required
          :class="{ 'is-invalid': compare_password_error }"
        />
        <p
          v-if="compare_password_error"
          class="error mt-2"
        >
          Пароли не совпадают
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 b-agree">
        <b-form-checkbox v-model="form.agree">
          Нажимая на кнопку “Создать аккаунт”, <br />
          я даю согласие на
          <router-link to="#">обработку персональных данных</router-link>
          и принимаю
          <a
            href="https://entersight.ru/agreement/"
            target="_blank"
            >условия лицензионного соглашения</a
          >
        </b-form-checkbox>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 mb-4">
        <b-button
          block
          type="submit"
          variant="primary"
          class="w-100 text-center justify-content-center"
          :disabled="!form.agree"
        >
          Создать аккаунт
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
  import { mapActions } from 'vuex'
  import { isEmail, isPhoneNumber } from '@/utils/rules'
  import PasswordInput from '../../shared/components/elements/PasswordInput.vue'
  import * as R from 'ramda'

  export default {
    components: {
      PasswordInput
    },
    data: () => ({
      form: {
        first_name: '',
        last_name: '',
        second_name: '',
        login: '',
        phone: '',
        password: '',
        confirm_password: '',
        agree: false
      },
      login_error: false,
      phone_error: false,
      compare_password_error: false
    }),

    methods: {
      ...mapActions({
        register_user: 'core/registerUser'
      }),

      async submitHandler() {
        const { first_name, login, phone, password, confirm_password } = this.form

        const fullName = first_name.split(' ')

        const userDetails = {
          first_name: fullName[1] || '',
          second_name: fullName[2] || '',
          last_name: fullName[0] || '',
          password: password,
          email: login,
          phone: phone
        }

        this.login_error = false
        this.phone_error = false
        this.compare_password_error = false

        if (!isEmail(login)) this.login_error = true
        if (!isPhoneNumber(phone)) this.phone_error = true
        if (password !== confirm_password) this.compare_password_error = true

        if (this.login_error || this.phone_error || this.compare_password_error) return false

        this.register_user(userDetails).catch(({ graphQLErrors }) => {
          const msg = R.pathOr(null, [0, 'extensions', 'ru'], graphQLErrors)
          this.$noty.show(msg)
        })
        const date = new Date().setMinutes(new Date().getMinutes() + 1)
        localStorage.setItem('interval_register_confirm', date.toString())
      }
    }
  }
</script>
