var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-auth"
  }, [_vm.layout === 'login' ? [_c('login')] : _vm.layout === 'registr' ? [_c('registr')] : _vm.layout === 'process' ? [_c('registr-process')] : _vm.layout === 'reset-password' ? [_c('reset-password')] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }