var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-auth__wrapper"
  }, [_c('div', {
    staticClass: "b-auth__form"
  }, [_vm._m(0), _c('form-info'), _c('div', {
    staticClass: "b-auth__login"
  }, [_vm._v(" Уже зарегистрированы? "), _c('router-link', {
    attrs: {
      "to": "/auth/login"
    }
  }, [_vm._v("Войти")])], 1)], 1), _c('slider')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "head-text center"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('img', {
    attrs: {
      "src": require("../../../public/img/icons/newLogo.svg")
    }
  })]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("Создание аккаунта")])]);

}]

export { render, staticRenderFns }