var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitHandler.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 mb-4"
  }, [_c('label', [_vm._v("Как вас зовут?")]), _c('b-form-input', {
    attrs: {
      "maxlength": "20",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.first_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "first_name", $$v);
      },
      expression: "form.first_name"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 mb-4"
  }, [_c('label', [_vm._v("E-mail")]), _c('b-form-input', {
    class: {
      'is-invalid': _vm.login_error
    },
    attrs: {
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.login,
      callback: function ($$v) {
        _vm.$set(_vm.form, "login", $$v);
      },
      expression: "form.login"
    }
  }), _vm.login_error ? _c('p', {
    staticClass: "error mt-2"
  }, [_vm._v(" Введите корректный email ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 mb-4"
  }, [_c('label', [_vm._v("Контактный телефон")]), _c('b-form-input', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '+7##########',
      expression: "'+7##########'"
    }],
    class: {
      'is-invalid': _vm.phone_error
    },
    attrs: {
      "placeholder": "+7XXXXXXXXXX",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }), _vm.phone_error ? _c('p', {
    staticClass: "error mt-2"
  }, [_vm._v(" Введите корректный телефон ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 mb-4"
  }, [_c('label', [_vm._v("Придумайте пароль")]), _c('password-input', {
    attrs: {
      "autocomplete": "on",
      "required": ""
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 mb-4"
  }, [_c('label', [_vm._v("Повторите пароль")]), _c('password-input', {
    class: {
      'is-invalid': _vm.compare_password_error
    },
    attrs: {
      "autocomplete": "on",
      "required": ""
    },
    model: {
      value: _vm.form.confirm_password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "confirm_password", $$v);
      },
      expression: "form.confirm_password"
    }
  }), _vm.compare_password_error ? _c('p', {
    staticClass: "error mt-2"
  }, [_vm._v(" Пароли не совпадают ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 b-agree"
  }, [_c('b-form-checkbox', {
    model: {
      value: _vm.form.agree,
      callback: function ($$v) {
        _vm.$set(_vm.form, "agree", $$v);
      },
      expression: "form.agree"
    }
  }, [_vm._v(" Нажимая на кнопку “Создать аккаунт”, "), _c('br'), _vm._v(" я даю согласие на "), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("обработку персональных данных")]), _vm._v(" и принимаю "), _c('a', {
    attrs: {
      "href": "https://entersight.ru/agreement/",
      "target": "_blank"
    }
  }, [_vm._v("условия лицензионного соглашения")])], 1)], 1)]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-12 mb-4"
  }, [_c('b-button', {
    staticClass: "w-100 text-center justify-content-center",
    attrs: {
      "block": "",
      "type": "submit",
      "variant": "primary",
      "disabled": !_vm.form.agree
    }
  }, [_vm._v(" Создать аккаунт ")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }